import { truncate } from 'lodash';
import moment from 'moment';

export const formatMoney = (number, currency) => {
  try {
    return number.toLocaleString('en-US', {
      style: 'currency',
      currency,
    });
  } catch (e) {
    return number.toLocaleString('en-US');
  }
};

export const parseCurrency = (amount) => {
  return `${Number(amount).toFixed(2)}`;
};

export const calcSubTotal = (list) => {
  const sum = list.reduce(
    (a, b) => a + (b['unitPrice'] * Number(b['quantity']) || 0),
    0
  );
  return sum;
};

export const formatDate = (date, dateFormat) => {
  return date ? moment(`${date}`).format(dateFormat) : 'N/A';
};

export function toUpperCaseFirstLetter(val) {
  return val[0].toUpperCase() + val.substr(1, val.length - 1);
}

export const truncateString = (str, num, end) => {
  if (str && str?.length > num) {
    return `${str?.slice(0, num)} ${end || ''}`;
  } else {
    return str || '';
  }
};

export const truncateWords = (str, options) => {
  const newOptions = {
    length: options?.length || 12,
    omission: options?.omission || '...',
  };
  return options ? truncate(str, newOptions) : truncate(str);
};

export const isEmptyStr = (str) => {
  return str && str !== '' ? true : null;
};

export const getDropdownOptions = (totalPages) => {
  const arr = Array.from(Array(totalPages).keys());
  return arr.map((item) => {
    return { label: `${Number(item) + 1}`, value: item + 1 };
  });
};

export const removeEmptyString = (data) => {
  const newObj = {};
  Object.keys(data).forEach((prop) => {
    if (data[prop] !== undefined && data[prop] !== null && data[prop] !== '') {
      newObj[prop] = data[prop];
    }
  });
  return newObj;
};

export const findAndRemoveExistingProduct = (arr2, arr1) => {
  let result = [...arr2];
  const big = arr2?.map((x) => x?.code);
  const small = arr1?.map((x) => x?.code);
  small?.map((x) => {
    if (big?.find((y) => y === x)) {
      result = result?.filter((z) => z?.code !== x);
    }
  });

  return result;
};
