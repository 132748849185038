import axios from 'axios';
import Swal from 'sweetalert2';

const headers = {};
export const timoutReq = 30000;

export const baseUrl =
  process.env.REACT_APP_BASE_URL || 'https://devapi-admin.wekurnect.io/v1';

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers,
  timeout: timoutReq,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    const { config, response } = error;

    if (error.message) {
      if (
        error.message === 'Network Error' ||
        error.message === `timeout of ${timoutReq}ms exceeded`
      ) {
        // console.log('dfadfa here', error.message, error.response._response);
        Swal.fire(
          "We're having trouble loading your data, please try again later." ||
            'Network Error, please try again later.',
          '',
          'error'
        );

        if (error.message === 'Request failed with status code 404') {
          Swal.fire('Network Error, please try again later.', '', 'error');
        }
        // return;
        // return { error: { message: 'Network Error, please try again later.' } };
        // return new Promise((resolve, reject) => {
        //   reject(error);
        // });
      }

      if (
        response &&
        response.status === 401 &&
        response.message === 'Token Expired!!'
      ) {
        // use refresh token here and save to localstorage
      }
    }

    if (!error.response) {
      return new Promise((resolve, reject) => {
        // console.log('>> gets here error 1');
        reject(error);
      });
    }

    // if (error.response.status === 403 || error.response.status === 401) {
    if (error.response.status === 403) {
      // console.log('>> gets here error');
      // navigate(REG, { tokenExpired: true });
      return error;
    } else if (error.response.status === 500) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
      // return error;
    }
  }
);

export default axiosInstance;
