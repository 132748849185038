import React, { createContext, useReducer, useState } from 'react';
import axiosInstance from '../helpers/axiosInstance';
import { removeEmptyString } from '../helpers/helper';
import authInitialState from './initialStates/authState';
import auth from './reducers/auth';
import staff from './reducers/staff';

export const GlobalContext = createContext({});

const GlobalProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(auth, authInitialState);
  const [staffs, setStaffs] = useState([]);
  const [staffInfo, setStaffInfo] = useState({
    email: '',
    phoneNumber: '',
    password: '',
    firstName: '',
    lastName: '',
    state: null,
    lga: null,
    staffId: '',
    basePay: '',
    role: '',
    startDate: null,
  });

  const handleGetStaffs = async (pageNumber, pageSize) => {
    const params = removeEmptyString({
      pageNumber,
      pageSize,
    });

    const res = await axiosInstance.get(`/Users`, {
      params,
    });
    setStaffs(res?.data?.data);
  };

  const handleGetAStaff = async (id) => {
    const res = await axiosInstance.get(`/Users/${id}`);
    setStaffInfo(res?.data?.data);
  };

  return (
    <GlobalContext.Provider
      value={{
        authState,
        authDispatch,
        staffs,
        setStaffs,
        handleGetStaffs,
        staffInfo,
        setStaffInfo,
        handleGetAStaff,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
